@import "../../../../resources/css/variables";

.icon-columns {
	background: darken($primary, 5%);
	padding-top: 50px;
	padding-bottom: 50px;
	
	.icon-column {
		text-align: center;
		padding: 30px;
		background: $primary;
		border-radius: 15px;
		
		@media(max-width: 991px) {
			margin-bottom: 	30px;		
		}

		i {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 150px;
			height: 150px;
			font-size: 55px;
			text-align: center;
			border: 3px solid $white;
			border-radius: 50%;
			margin: 0 auto 15px;
			color: $white;
			
			@media(max-width: 500px) {
				width: 120px;
				height: 120px;
				font-size: 45px;
			}
		}
		
		.icon-column-title {
			font-size: 1.8em;
			justify-content: center;
			align-items: center;
			margin-top: 0;
			border-bottom-color: $primary-xl;
			
			a {
				color: $white;
			}
		}
		
		p {
			margin-bottom: 0;
			color: $white;
			text-align: left;
		}	
	}
	
	[class^="col-"]:last-child .icon-column {
		margin-bottom: 0;
	}
	
	@if $scheme-type == 'dark' {
		background: $base-accent;
		border-top: 1px solid $base-accent-xxl;
		
		.icon-column {
			background: $base-accent-l;
			border: 1px solid $base-accent-xxl;
			
			.icon-column-title {
				border-bottom-color: $neutral-d;
			}
		}
	} @else if $scheme-type == 'light' {
		background: $base-accent;
		border-top: 1px solid $base-accent-xxd;
		
		.icon-column {
			background: $base-accent-d;
			border: 1px solid lighten($neutral-l, 3%);
			
			.icon-column-title {
				border-bottom-color: $neutral;
				
				a {
					color: $text;
				}
			}
			
			p  {
				color: $text;
			}
			
			i {
				color: $primary;
				border-color: $primary;
			}
		}
	}
}