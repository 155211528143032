@import "../../../../resources/css/variables";

.circle-articles {
	.circle-articles__img {
		display: block;
		width: 100%;
		max-width: 220px;
		margin: 26px auto 20px;
		background-size: cover;
		background-position: center center;
		border-radius: 50%;
		
		&:before {
			content: "";
			display: block;
			padding-top: 100%;
		}
	}
	
	.circle-articles__title {
		font-size: 1.22em;
		text-align: center;
		margin-bottom: 26px;
	}
	
	.circle-articles__sub-title {
		font-size: 1em;
		text-align: center;
		margin-top: -20px;
		margin-bottom: 26px;
	}
}


















