/* Generated by Font Squirrel (https://www.fontsquirrel.com) on April 4, 2016 */

@font-face {
    font-family: 'cousine';
    src: url('cousine-regular-webfont.eot');
    src: url('cousine-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('cousine-regular-webfont.woff2') format('woff2'),
         url('cousine-regular-webfont.woff') format('woff'),
         url('cousine-regular-webfont.ttf') format('truetype'),
         url('cousine-regular-webfont.svg#cousineregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'cousine';
    src: url('cousine-bold-webfont.eot');
    src: url('cousine-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('cousine-bold-webfont.woff2') format('woff2'),
         url('cousine-bold-webfont.woff') format('woff'),
         url('cousine-bold-webfont.ttf') format('truetype'),
         url('cousine-bold-webfont.svg#cousinebold') format('svg');
    font-weight: bold;
    font-style: normal;

}