@import "variables";

/*||--------------------------------------------------------||**
**||				  <- General Styles ->					||**
**||--------------------------------------------------------||*/
html, body {
	&.no-scroll {
		overflow: hidden;
	}
}

body {
	font-family: "roboto", Arial, sans-serif;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	color: $text;

	@media(max-width: 575px) {
		font-size: 14px;
	}
}

#tinymce {
	padding: 30px;

	//Set this if you need the body color to match in the TinyMCE Editor
	background: $base-accent;
}

.uwy, body .uwy {
	z-index: 9800 !important;
}

.img-fluid {
	margin: 0 auto 20px;
}

.container {
	width: auto;
	max-width: 1400px;
	margin: 0 auto;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0 0 10px 0;
}

h1 {
	font-size: 1.7em;
	font-weight: normal;
	line-height: 1.4;
	margin-bottom: 15px;

	&.title-underlined {
		margin-top: 0;
	}
}

h2 {
	font-size: 1.4em;
	line-height: 1.4;
	font-weight: bold;
	color: $primary;
}

h3 {
	font-size: 1.13em;
	font-weight: 400;
	line-height: 1.4;
	text-transform: uppercase;
	letter-spacing: .5px;
	color: lighten($text, 10%);
}

h4 {
	font-size: 1.13em;
	font-weight: 400;
	line-height: 1.4;
	color: $text;
}

@if $scheme-type == "dark" {
	h2, h3 {
		color: $text;
	}
}

h5, h6 {
	font-size: 1.1em;
	line-height: 1.4;
}

.title-upper {
	text-transform: uppercase !important;
}

.title-capital {
	text-transform: capitalize !important;
}

.title-bar {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: stretch;
	margin: 28px 0 15px;
	overflow: hidden;

	h1, h2, h3, h4, h5, h6 {
		display: flex;
		flex: 1;
		align-items: center;
		background: $primary;
		margin: 0;
		padding: 17px 30px;
		color: $white;
		border-radius: 8px;
		font-size: 1.25em;
		font-weight: bold;
		text-transform: none;
		letter-spacing: normal;

		a {
			color: $white;
		}

		@if $scheme-type == "dark" {
			background: $base-accent-l;
			border: 1px solid $base-accent-xxl;
		} @else if $scheme-type == "light" {
			background: $base-accent-d;
			border: 1px solid $base-accent-xxd;
			color: $text;

			a {
				color: $text;
			}
		}

		@media(max-width: 450px) {
			font-size: 1.2em;
		}

		@media(max-width: 350px) {
			padding: 14px 20px;
		}
	}

	&.title-bar-lg {
		h1, h2, h3, h4, h5, h6 {
			font-size: 1.5em;
			padding: 25px 30px;
		}
	}

	&.title-bar-topper {
		h1, h2, h3, h4, h5, h6 {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	i {
		display: flex;
		align-items: center;
		background: $accent;
		color: $white;
		font-size: 2.1em;
		padding: 16px;
		border-right: 3px solid $white;
		border-radius: 8px 0 0 8px;

		@if $scheme-type == "dark" {
			background: $primary;
			border-right: none;
		} @else if $scheme-type == "light" {
			background: $mildorwild-green;
			color: $white;
			border-right: 3px solid $white;
		}

		@media(max-width: 450px) {
			font-size: 1.7em;
			padding: 12px;
			border-right-width: 2px;
		}
	}

	i + h1,
	i + h2,
	i + h3,
	i + h4,
	i + h5,
	i + h6 {
		padding-left: 20px !important;
		border-left: none;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;

		@if $scheme-type == "light" {
			padding-left: 0;
		}
	}

	img {
		display: flex;
		align-items: center;
		border-right: 3px solid $white;
		border-radius: 8px 0 0 8px;
		height: 64px;

		@if $scheme-type == "dark" {
			border-right: none;
		} @else if $scheme-type == "light" {
			border: 1px solid $base-accent-xxd;
			border-right: none;
		}

		@media(max-width: 450px) {
			border-right-width: 2px;
		}

		+ h1,
		+ h2,
		+ h3,
		+ h4,
		+ h5,
		+ h6 {
			padding-left: 20px;
			border-left: none;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;

			@if $scheme-type == "light" {
				padding-left: 10px;
			}
		}
	}
}

.title-underlined {
	display: flex;
	align-items: flex-start;
	border-bottom: 1px solid $neutral;
	padding-bottom: 12px;
	margin-top: 20px;
	margin-bottom: 15px;

	@if $scheme-type == "dark" {
		border-bottom: 1px solid $neutral-d;
	}

	i {
		font-size: 1.2em;
		line-height: 1.2;
		margin-right: 13px;
		color: $accent;
	}
}

.title-super-sm,
.title-super-md,
.title-super-lg,
.title-super-xl {
	font-size: 1.8em;
	font-weight: 300;
	line-height: 1.4;
	color: lighten($text, 20%);
	margin-top: 20px;
	margin-bottom: 15px;
	text-transform: none;

	b, strong {
		font-weight: bold;
		color: $primary;
		text-transform: uppercase;
	}

	&.bold {
		font-weight: bold;
	}

	@media(max-width: 500px) {
		font-size: 1.5em;
	}

	@if $scheme-type == "dark" {
		color: lighten($neutral-l, 2%);

		b, strong {
			color: $white;
		}
	}
}

.title-super-md {
	font-size: 2.3em;

	@media(max-width: 500px) {
		font-size: 1.9em;
	}
}

.title-super-lg {
	font-size: 2.8em;

	@media(max-width: 500px) {
		font-size: 2.2em;
	}
}

.title-super-xl {
	font-size: 3.3em;

	@media(max-width: 500px) {
		font-size: 2.5em;
	}
}

.content-super {
	font-size: 1.4em !important;

	@media(max-width: 500px) {
		font-size: 1.2em !important;
	}
}

.accent-mark {
	display: flex;
	align-items: flex-start;

	&:before {
		content: "\f330";
		font-family: "Font Awesome 5 Pro";
		font-size: 1.2em;
		font-weight: 400;
		color: $accent;
		margin-right: 10px;
		line-height: 1.2;
	}
}

p {
	font-size: 1em;
	line-height: 1.5;
	margin: 0 0 15px 0;
}

a {
	color: $accent-d;

	&:hover {
		text-decoration: none;
		color: $accent;
	}

	&:focus {
		text-decoration: none;
	}

	@if $scheme-type == "dark" {
		color: $accent-xl;

		&:hover {
			color: $accent-xxl;
		}
	}
}

ul, ol {
	padding-left: 20px;
	margin-bottom: 20px;

	li {
		margin-bottom: 10px;
	}
}

.icon-list {
	list-style: none;
	padding: 0;

	li {
		position: relative;
		display: flex;
		align-items: flex-start;

		i {
			font-size: 1.1em;
			line-height: 1.3;
			margin-right: 11px;
			color: $accent;
		}
	}
}

//Add this on to a .row that has a split list inside
.split-list {
	margin-bottom: 12px;

	ul, ol {
		margin-bottom: 0;
	}
}

.text-primary {
	color: $primary !important;
}

.text-accent {
	color: $accent !important;
}

.text-neutral {
	color: $neutral !important;
}

.text-default {
	color: $text !important;
}

.text-danger {
	color: $alert !important;
}

.text-success {
	color: $success !important;
}

.text-muted {
	color: $neutral-d !important;
}

.background-primary {
	background: $primary !important;
}

.background-accent {
	background: $accent !important;
}

.background-neutral {
	background: $neutral !important;
}

.background-base {
	background: $base !important;
}

.background-base-accent {
	background: $base-accent !important;
}

hr {
	border: none;
	border-top: 1px solid $neutral;
	margin: 25px 0;

	@if $scheme-type == "dark" {
		border-top: 1px solid $neutral-d;
	}
}

.border {
	border-radius: 8px;
	overflow: hidden;

	@if $scheme-type == "dark" {
		border-color: $base-accent-xxl !important;
	} @else if $scheme-type == "light" {
		border-color: $base-accent-xxd !important;
	} @else {
		border: none !important;
	}
}

.icon-lg {
	@if $scheme-type == "dark" {
		border-color: $white;
		color: $white;
	} @else if $scheme-type == "light" {
		border-color: $neutral;
		color: $neutral;
	}
}

.trim {
	background: $base;
	border-radius: 15px;
	padding: 30px 30px 15px;
	margin: 30px 0;
	box-shadow: 0 0 20px rgba($black, .05);

	@if $scheme-type == "dark" {
		background: $base-accent;
		border: 1px solid $base-accent-xxl;
	} @else if $scheme-type == "light" {
		background: $base;
		border: 1px solid $base-accent-xxd;
		box-shadow: 0 0 20px rgba($black, .05);
	}

	@media(max-width: 450px) {
		padding: 25px 25px 10px;
	}

	@media(max-width: 350px) {
		padding: 20px 20px 5px;
	}

	.title-bar:first-child,
	.title-underlined:first-child,
	.title-super-sm:first-child,
	.title-super-md:first-child,
	.title-super-lg:first-child {
		margin-top: 0;
	}
}

//Margin fixes for trim containers, also see additional fixes in site-scripts.js
.row > [class^="col"] > .trim:first-child {
	margin-top: 0;
}

.title-bar-trim-combo {
	margin: 30px 0;

	.title-bar {
		margin: 0;

		h1, h2, h3, h4, h5, h6 {
			border-radius: 15px 15px 0 0;
		}

		i {
			border-radius: 15px 0 0 0;
		}

		i + h1,
		i + h2,
		i + h3,
		i + h4,
		i + h5,
		i + h6 {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}

		img {
			border-radius: 15px 0 0 0;

			+ h1,
			+ h2,
			+ h3,
			+ h4,
			+ h5,
			+ h6 {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
	}

	.trim {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border-top: none;
		margin: 0;
	}
}

.row > [class^="col"] > .title-bar-trim-combo:first-child {
	margin-top: 0;
}


/*||--------------------------------------------------------||**
**||				  <- Layout Styles ->					||**
**||--------------------------------------------------------||*/
#main-wrapper {
	position: relative;
	background: $base-accent;

	@if $scheme-type == "dark" {
		background: $base;
	}

	//These style are for the sticky footer
	display: flex;
	min-height: 100vh;
	flex-direction: column;

	#content-area {
		//This Flex style is for the sticky footer
		flex: 1 0 auto;

		.main-content {
			padding-top: 40px;
			padding-bottom: 40px;

			@media(max-width: 600px) {
				padding-top: 30px;
				padding-bottom: 30px;
			}

			@media(max-width: 350px) {
				.container {
					padding-left: 10px;
					padding-right: 10px;
				}
			}

			@if $scheme-type == "dark" {
				border-top: 1px solid $base-accent-xxl;
			} @else if $scheme-type == "light" {
				border-top: 1px solid $base-accent-xxd;

				&:first-child {
					border-top: none;
				}
			}
		}

		+ .main-content {
			@if $scheme-type == "dark" {
				border-top: 1px solid $base-accent-xxl;
			} @else if $scheme-type == "light" {
				border-top: 1px solid $base-accent-xxd;
			}
		}
	}
}


/*||--------------------------------------------------------||**
**||			  <- Social Links Styles ->					||**
**||--------------------------------------------------------||*/
.social-links {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 0;

	h3 {
		font-size: 15px;
		color: $accent;
		margin: 0 8px 0 0;
	}

	.social-btn {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 45px;
		height: 45px;
		font-size: 20px;
		background: none;
		border: 2px solid $primary;
		border-radius: 50%;
		color: $primary;
		margin: 0 3px;

		&:hover {
			background: rgba($primary, .08);
		}

		@if $scheme-type == "dark" {
			border-color: $white;
			color: $white;

			&:hover {
				text-decoration: none;
				background: rgba($white, .08);
			}
		} @else if $scheme-type == "light" {
			border-color: $neutral-xd;
			color: $neutral-xd;

			&:hover {
				text-decoration: none;
				background: rgba($neutral-d, .08);
			}
		}
	}

	@media(max-width: 575px) {
		.social-btn {
			width: 42px;
			height: 42px;
			font-size: 19px;
		}
	}
}

/*||--------------------------------------------------------||**
**||			 <- Social Share Button ->					||**
**||--------------------------------------------------------||*/
.social {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 1001;
	outline: none;

	&:focus {
		.share-btn {
			opacity: 1;
		}
	}

	.share-btn {
		position: relative;
		z-index: 1;
		opacity: .7;
		padding: 30px 30px 15px 15px;
		text-align: center;
		background: rgba(237, 28, 35, 1);
		background: $primary;
		color: $white;
		border-radius: 0 100% 0 0;
		cursor: pointer;
		font-size: 16px;
		@include transition(padding .3s cubic-bezier(0.12, 1.1, 0.47, 1.93), opacity .3s);

		@if $scheme-type == "dark" {
			background: $base-accent-xl;
		} @else if $scheme-type == "light" {
			background: rgba(237, 28, 35, 1);
			background: $primary;
		}

		&:hover {
			opacity: 1;
			padding: 35px 35px 20px 20px;
		}

		p {
			margin: 0;
			color: $white;
			font-weight: bold;
		}
	}

	a {
		position: absolute;
		bottom: -40px;
		left: -40px;
		@include transition(all .25s ease-in);

		i {
			width: 37px;
			height: 37px;
			line-height: 37px;
			font-size: 18px;
			font-weight: 900;
			text-align: center;
			color: $white;
			border-radius: 50%;
			@include transition(all .25s cubic-bezier(0.02, 0.43, 0.35, 1.46));

			&:hover {
				@include transform(scale(1.12));
			}
		}

		&:nth-child(3) {
			@include transition-delay(.1s);
		}

		&:nth-child(4) {
			@include transition-delay(.2s);
		}

		&:nth-child(5) {
			@include transition-delay(.3s);
		}
	}

	&.active {
		.share-btn {
			opacity: 1;
			padding: 35px 35px 20px 20px;
		}

		a {
			@include transition(all .25s cubic-bezier(0.02, 0.43, 0.35, 1.46));
		}

		a:nth-child(2) {
			bottom: 110px;
			left: 10px;
		}

		a:nth-child(3) {
			bottom: 91px;
			left: 55px;
			@include transition-delay(.1s);
		}

		a:nth-child(4) {
			bottom: 54px;
			left: 87px;
			@include transition-delay(.2s);
		}

		a:nth-child(5) {
			bottom: 10px;
			left: 105px;
			@include transition-delay(.3s);
		}
	}

	@media(max-width: 600px) {
		.share-btn {
			padding: 26px 26px 13px 13px;
			@include transition-delay(.4s);

			&:hover {
				opacity: .7;
				padding: 26px 26px 13px 13px;
			}

			i {
				font-size: 18px;
			}

			p {
				display: none;
			}
		}

		a:nth-child(2) {
			@include transition-delay(0s);
		}

		a:nth-child(3) {
			@include transition-delay(.1s);
		}

		a:nth-child(4) {
			@include transition-delay(.2s);
		}

		a:nth-child(5) {
			@include transition-delay(.3s);
		}

		&.active {
			.share-btn {
				padding: 50px 50px 29px 36px;
				opacity: 1;
				@include transition-delay(0s);
			}

			a:nth-child(2) {
				@include transition-delay(.1s);
			}

			a:nth-child(3) {
				@include transition-delay(.2s);
			}

			a:nth-child(4) {
				@include transition-delay(.3s);
			}

			a:nth-child(5) {
				@include transition-delay(.4s);
			}
		}
	}
}

/*||--------------------------------------------------------||**
**||				  <- Button Styles ->					||**
**||--------------------------------------------------------||*/
.btn-outline {
	@if $scheme-type == "dark" {
		border-color: $neutral;
		color: $neutral-l;

		&:hover, &:focus, &:active, &:active:focus {
			background-color: rgba($neutral, .1) !important;
			color: $neutral-xxl !important;
			border-color: $neutral-l !important;
		}
	} @else if $scheme-type == "light" {
		border-color: $neutral-d;
		color: darken($neutral-xd, 5%);

		&:hover, &:focus, &:active, &:active:focus {
			background-color: rgba($neutral, .1) !important;
			color: $neutral-xd !important;
		}
	}
}

//Fixed Button
.fixed-btn {
	position: fixed;
	bottom: 13px;
	right: 70px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 42px;
	padding: 0 20px;
	z-index: 1001;

	span {
		margin-left: 7px;
	}

	@media(max-width: 600px) {
		right: 65px;
		border-radius: 50%;
		width: 46px;
		height: 46px;
		padding: 0;

		span {
			display: none;
		}
	}
}

//Add additional padding to button of footer so fixed button doesn't block footer content
@media(max-width: 1450px) {
	#footer-wrapper {
		padding-bottom: 75px !important;
	}
}


/*||--------------------------------------------------------||**
**||				  <- Video Styles ->					||**
**||--------------------------------------------------------||*/
.video-wrap {
	max-width: 600px;
	display: block;
	margin: 0 auto;

	.video-img-wrap {
		position: relative;

		&:before {
			content: "\f167";
			font-family: "Font Awesome 5 Brands";
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			margin: -35px auto;
			font-size: 70px;
			line-height: 70px;
			text-align: center;
			width: 80px;
			height: 70px;
			color: $primary;
			text-shadow: 0 0 15px #FFF;
			opacity: .7;
			@include transition(all .2s);
		}

		.img-fluid {
			margin-bottom: 0;
		}
	}

	.video-title {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 18px 20px;
		background: $primary;

		h2, h3, h4 {
			color: $white;
			text-align: center;
			margin: 0;
		}

		@if $scheme-type == "dark" {
			background: $base-accent-xl;
		} @else if $scheme-type == "light" {
			background: $base-accent-d;

			h2, h3, h4 {
				color: $text;
			}
		}
	}

	&:hover, &:focus {
		text-decoration: none;

		.video-img-wrap:before {
			opacity: 1;
		}
	}
}


/*||--------------------------------------------------------||**
**||					 <- Form Styles ->					||**
**||--------------------------------------------------------||*/
$form-border: $neutral-l;
$form-background: rgba($white, .5);

@if $scheme-type == "dark" {
	$form-border: $neutral-d;
	$form-background: none;
}

.form-control {
	box-shadow: none;
	border-color: $form-border;
	background: $form-background;
	-webkit-appearance: none;
	color: $text;

	&:focus {
		background: $form-background;
		border-color: $form-border;
		color: $text;
	}

	&[readonly] {
		background: $neutral-xxl;

		@if $scheme-type == "dark" {
			background: $base-accent-xl;
		}
	}

	&.is-invalid {
		border-color: $alert;
		background: none;
	}
}

.invalid-feedback {
	color: $alert;
}

.input-group-text {
	background: $neutral-xxl;
	border-color: $form-border;
	color: $text;

	@if $scheme-type == "dark" {
		background: $base-accent-l;
	}
}

.input-group {

	.btn {
		padding: 0.375rem 0.75rem;
		border-width: 1px;
	}

	.btn-form {
		background-color: $neutral-xxl;
		border-color: $form-border;
		color: darken($neutral, 5%);

		&:hover, &:focus, &:active, &:active:focus {
			background-color: darken($neutral-xxl, 5%) !important;
			color: $neutral-d !important;
		}
	}
}

//Flat Picker Override Styles
.flatpickr-input + input.form-control[readonly] {
	background: $form-background;
}

//Select Box Overrides
.select-wrap .select-box:after {
	color: $form-border;
	border-left: 1px solid $form-border;
}

//Check Box Overrides
.checkbox .check-btn {
	background: $form-background;
	box-shadow: inset 0 0 0 1px $form-border;
}

//Item Quantity Buttons
.item-quantity {
	input {
		@if $scheme-type == "dark" {
			border-color: $white;
		}
	}
}

::-webkit-input-placeholder {
	color: gray;
}

::-moz-placeholder {
	color: gray;
}

:-ms-input-placeholder {
	color: gray;
}

input:-moz-placeholder {
	color: gray;
}

@if $scheme-type == "dark" {
	.realperson-challenge .realperson-text {
		color: $text !important;
	}

	.realperson-regen, .realperson-audio {
		color: $primary;
	}
}


/*||--------------------------------------------------------||**
**||				 <- Modal Styles ->						||**
**||--------------------------------------------------------||*/
.modal .modal-dialog .modal-content {
	.modal-header {
		> i {
			@if $scheme-type == "light" {
				background: none;
				color: $text;
			}
		}

		@if $scheme-type == "dark" {
			background: $base-accent-l;
			box-shadow: none;
		} @else if $scheme-type == "light" {
			background: $base-accent-d;
			border-bottom: 1px solid $base-accent-xxd;
			box-shadow: none;

			.modal-title {
				color: $text;
			}
		}

		&.text-white {
			i, .modal-title {
				color: inherit !important;
			}
		}
	}

	.modal-body {
		@if $scheme-type == "light" {
			background: $base;
		}
	}

	.modal-footer {
		@if $scheme-type == "light" {
			background: $base;
		}
	}
}

#alert-modal,
#success-modal {
	.modal-header {
		@if $scheme-type == "dark" {
			background: $base-accent-l !important;
		}

		> i {
			@if $scheme-type == "dark" {
				color: $white !important;
				background: darken($alert, 10%) !important;
			} @else if $scheme-type == "light" {
				background: $alert !important;
				color: $white !important;
			}
		}
	}
}

#success-modal {
	.modal-header {
		@if $scheme-type == "dark" {
			background: $base-accent-l !important;
		}

		> i {
			@if $scheme-type == "dark" {
				color: $white !important;
				background: darken($success, 10%);
			} @else if $scheme-type == "light" {
				background: $success !important;
				color: $white !important;
			}
		}
	}
}


/*||--------------------------------------------------------||**
**||			 <- Photo Gallery Styles ->					||**
**||--------------------------------------------------------||*/

.lightbox {
	text-align: center;

	a {
		position: relative;
		display: block;
		border: none;

		&:after {
			opacity: 0;
			@include transform(scale(.5));
			content: "\f002";
			font-family: "Font Awesome 5 Pro";
			font-weight: 400;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 70px;
			height: 70px;
			font-size: 27px;
			border: 2px solid #FFF;
			border-radius: 50%;
			background: rgba($primary, .7);
			box-shadow: -2px 2px 10px rgba($black, .3);
			color: #FFF;
			text-shadow: 0 0 20px rgba($black, .3);
			text-align: center;
			z-index: 2;
			@include transition(all .2s ease-in);
		}

		&:hover, &:focus {
			&:after {
				opacity: 1;
				@include transform(scale(1));
				@include transition(all .2s ease-out);
			}
		}
	}

	&.gallery {
		margin-top: 30px;

		a {
			display: block;
			margin-bottom: 30px;

			&:after {
				width: 52px;
				height: 52px;
				font-size: 21px;
			}

			img {
				display: block;
				max-width: 100%;
				margin: 0 auto;
			}
		}

		@media(max-width: 450px) {
			margin-right: -7px;
			margin-left: -7px;

			div[class^="col"] {
				padding-right: 7px;
				padding-left: 7px;
			}

			a {
				margin-bottom: 14px;

				&:after {
					display: none;
				}
			}

			& + * {
				margin-top: 15px;
			}
		}
	}
}

#pbCloseBtn:before, #pbAutoplayBtn:before {
	background: $accent;
	color: #FFF;
}

#pbCloseBtn:hover:before, #pbAutoplayBtn:hover:before {
	opacity: 1;
	background: $accent;
}

.pbThumbs li.active a img {
	border-color: $accent;
}


/*||--------------------------------------------------------||**
**||			 <- Toolbar Override Styles ->				||**
**||--------------------------------------------------------||*/
.toolbar-header,
.toolbar-footer,
.toolbar-title {
	@if $scheme-type == "dark" {
		background: $base-accent-l;
		border: 1px solid $base-accent-xxl;
	} @else if $scheme-type == "light" {
		background: $base-accent-d;
		border: 1px solid $base-accent-xxd;
	}
}


/*||--------------------------------------------------------||**
**||			 <- Responsive Tables Styles ->				||**
**||--------------------------------------------------------||*/
$resp-table-border: 1px solid $neutral;

@if $scheme-type == "dark" {
	$resp-table-border: 1px solid $neutral-xd;
}

.resp-table, .resp-table-lg {
	border-bottom-style: none;

	> .row {
		margin: 0;
		border-bottom: $resp-table-border;

		p {
			margin-bottom: 0;
			overflow: hidden;
			text-overflow: ellipsis;

			span {
				display: none;
				font-weight: bold;
			}
		}

		> [class^="col"] {
			padding-top: 10px;
			padding-bottom: 10px;
			border-right: $resp-table-border;
			min-width: 0;

			&:last-child {
				border-right: none;
			}

			&.resp-custom {
				display: block;
			}
		}

		&.title-row {
			background: $primary;

			[class^="col"] {
				border-color: $primary-xxl;

				p {
					color: $white;
					font-weight: bold;
				}
			}

			@if $scheme-type == "dark" {
				background: $neutral-xxd;

				[class^="col"] {
					border-color: darken($neutral-d, 5%);
				}
			} @else if $scheme-type == "light" {
				background: darken($base-accent-d, 3%);

				[class^="col"] {
					border-color: $neutral;

					p {
						color: $text;
					}
				}
			}
		}

		&.sub-title-row {
			background: $neutral-xl;

			@if $scheme-type == "dark" {
				background: $base-accent-l;
			} @else if $scheme-type == "light" {
				background: $base-accent;
			}

			p {
				font-weight: bold;
			}
		}

		.checks {
			i {
				font-size: 26px;
				color: $accent;
			}
		}
	}
}

//--------------------------------------------------------------------------------------------------------------------------------------
//The following mixins were setup so I could use the same styles for noraml and large size responsive tables at different breakpoints...
//otherwise I would have to have two sets of these same styles at different breakpoints and have to update them twice -Gene
//--------------------------------------------------------------------------------------------------------------------------------------

//This mixin is used to set styles for the responsive tables on desktop size
@mixin resp-table-desktop {
	> .row {
		> div[class^="col"] {
			display: flex;

			p {
				display: flex;
				align-items: center;
			}
		}

		.checks {
			display: table;
			width: 100%;
			height: 100%;
			text-align: center;

			i {
				display: table-cell;
				vertical-align: middle;
			}
		}
	}
}

//This mixin is used to set styles for the responsive tables on mobile size
@mixin resp-table-mobile {
	> .row {
		border-bottom: none;

		p span {
			display: inline-block;
		}

		> div[class^="col"] {
			border: none;
			padding-top: 5px;
			padding-bottom: 5px;

			&:first-child {
				background: $neutral-xl;
				border-bottom: $resp-table-border;
				padding-top: 10px;
				padding-bottom: 10px;

				@if $scheme-type == "dark" {
					background: $base-accent-l;
				} @else if $scheme-type == "light" {
					background: $base-accent;
				}

				p {
					font-weight: bold;
					white-space: normal;
				}
			}

			&:nth-child(2) {
				padding-top: 20px;
			}

			&:last-child {
				padding-bottom: 20px;
				border-bottom: $resp-table-border;
			}

			.checks {
				padding-left: 30px;

				i {
					position: absolute;
					top: 50%;
					left: 10px;
					margin-top: -13px;
				}

				&:after {
					white-space: normal;
				}
			}
		}

		&.title-row {
			display: none;
		}

		&.sub-title-row div[class^="col"] {
			background: $primary;
			padding-top: 10px;
			padding-bottom: 10px;

			p {
				color: $white;
			}

			@if $scheme-type == "dark" {
				background: $neutral-xxd;
			} @else if $scheme-type == "light" {
				background: darken($base-accent-d, 3%);

				p {
					color: $text;
				}
			}
		}
	}
}

//Mixin is being called to set styles for normal size responsive tables on desktop
@media(min-width: 768px) {
	.resp-table {
		@include resp-table-desktop;
	}
}

//Mixin is being called to set styles for large size responsive tables on desktop
@media(min-width: 992px) {
	.resp-table-lg {
		@include resp-table-desktop;
	}
}

//Mixin is being called to set styles for normal size responsive tables on mobile
@media(max-width: 767px) {
	.resp-table {
		@include resp-table-mobile;
	}
}

//Mixin is being called to set styles for large size responsive tables on mobile
@media(max-width: 991px) {
	.resp-table-lg {
		@include resp-table-mobile;
	}
}


/*||!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!||**
/*||!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!||**
**||------------------------------------------------------------||**
**||			 <- Secrets Specific Styles Below ->			||**
**||------------------------------------------------------------||**
/*||!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!||**
/*||!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!||*/

/*||--------------------------------------------------------||**
**||			 <- Color Chart Table Styles ->				||**
**||--------------------------------------------------------||*/
.color-table {
	thead {
		th {
			@if $scheme-type == "dark" {
				background: $base-accent-xl;
				border-bottom: none;
			} @else if $scheme-type == "light" {
				background: $base-accent-d;
				border-bottom: none;
			}
		}
	}

	tbody {
		th {
			&.green {
				background: #1CAB3B;
				color: $white;
			}

			&.red {
				background: #F2231D;
				color: $white;
			}

			&.blue {
				background: #038DF3;
				color: $white;
			}

			&.pink {
				background: #FF04A6;
				color: $white;
			}

			&.white {
				background: $white;
				color: $black;

				th, td {
					color: $text;
				}
			}

			&.purple {
				background: #5D0967;
				color: $white;
			}

			&.black {
				background: $black;
				color: $white;
			}
		}
	}
}


/*||--------------------------------------------------------||**
**||			 <- Events Carousel Styles ->				||**
**||--------------------------------------------------------||*/
.events-carousel-wrap {
	position: relative;
	background: $white;
	border-top: 1px solid $base-accent-xxd;
	width: 100%;
	padding: 15px 100px;

	@media(max-width: 450px) {
		padding: 15px 65px;
	}

	.events-carousel {
		.swiper-slide {
			img {
				display: block;
				width: 100%;
				max-width: 300px;
				margin: 0 auto;

				@media(max-width: 450px) {
					max-width: 200px;
				}
			}
		}
	}

	.swiper-button-next, .swiper-button-prev {
		background: none;
		width: 50px;
		height: 50px;
		margin-top: -15px;
		right: 10px;
		z-index: 99;
		outline: none;
		opacity: .7;
		@include transition(all .2s);

		&:before, &:after {
			display: none;
		}

		&.swiper-button-disabled {
			display: none;
		}

		i {
			font-size: 40px;
			color: $primary;

			@media(max-width: 600px) {
				font-size: 32px;
			}
		}

		@media(max-width: 450px) {
			width: 35px;
			height: 35px;

			i {
				font-size: 28px;
			}
		}

		&:hover {
			opacity: 1;
		}
	}

	.swiper-button-prev {
		right: auto;
		left: 10px;
	}
}

/*||--------------------------------------------------------||**
**||				 <- Club Map Styles ->					||**
**||--------------------------------------------------------||*/
#clubs-states-map {
	width: 100%;
	height: 670px;
	margin: 50px 0 60px;

	@media(max-width: 900px) {
		height: 600px;
	}

	@media(max-width: 800px) {
		height: 500px;
	}

	@media(max-width: 700px) {
		height: 400px;
	}

	@media(max-width: 600px) {
		height: 300px;
		padding-top: 30px;
		margin-top: 15px;
		margin-bottom: 40px;
	}

	@media(max-width: 500px) {
		height: 250px;
	}

	@media(max-width: 400px) {
		height: 200px;
	}

	.jqvmap-zoomin,
	.jqvmap-zoomout {
		display: flex;
		justify-content: center;
		align-items: center;
		top: 0;
		left: auto;
		right: 0;
		font-size: 26px;
		width: 30px;
		height: 30px;
		background: none;
		border: 2px solid $neutral;
		color: $neutral;

		&:hover {
			border-color: $primary;
			color: $primary;
		}
	}

	.jqvmap-zoomin {
		right: 40px;
	}

	@media(max-width: 600px) {
		.jqvmap-zoomin,
		.jqvmap-zoomout {
			width: 25px;
			height: 25px;
			font-size: 18px;
		}

		.jqvmap-zoomin {
			right: 30px;
		}
	}
}

/*||------------------------------------------------||**
**||				 <- Borders ->					||**
**||------------------------------------------------||*/
@media only screen and (min-width: 480px) {
	.border-sm {
		border: 1px solid #DEE2E6 !important;
	}
	.border-sm-top {
		border-top: 1px solid #DEE2E6 !important;
	}
	.border-sm-right {
		border-right: 1px solid #DEE2E6 !important;
	}
	.border-sm-bottom {
		border-bottom: 1px solid #DEE2E6 !important;
	}
	.border-sm-left {
		border-left: 1px solid #DEE2E6 !important;
	}

	.border-sm-0 {
		border: 0 !important;
	}
	.border-sm-top-0 {
		border-top: 0 !important;
	}
	.border-sm-right-0 {
		border-right: 0 !important;
	}
	.border-sm-bottom-0 {
		border-bottom: 0 !important;
	}
	.border-sm-left-0 {
		border-left: 0 !important;
	}
}

@media only screen and (min-width: 768px) {
	.border-md {
		border: 1px solid #DEE2E6 !important;
	}
	.border-md-top {
		border-top: 1px solid #DEE2E6 !important;
	}
	.border-md-right {
		border-right: 1px solid #DEE2E6 !important;
	}
	.border-md-bottom {
		border-bottom: 1px solid #DEE2E6 !important;
	}
	.border-md-left {
		border-left: 1px solid #DEE2E6 !important;
	}

	.border-md-0 {
		border: 0 !important;
	}
	.border-md-top-0 {
		border-top: 0 !important;
	}
	.border-md-right-0 {
		border-right: 0 !important;
	}
	.border-md-bottom-0 {
		border-bottom: 0 !important;
	}
	.border-md-left-0 {
		border-left: 0 !important;
	}
}

@media only screen and (min-width: 992px) {
	.border-lg {
		border: 1px solid #DEE2E6 !important;
	}
	.border-lg-top {
		border-top: 1px solid #DEE2E6 !important;
	}
	.border-lg-right {
		border-right: 1px solid #DEE2E6 !important;
	}
	.border-lg-bottom {
		border-bottom: 1px solid #DEE2E6 !important;
	}
	.border-lg-left {
		border-left: 1px solid #DEE2E6 !important;
	}

	.border-lg-0 {
		border: 0 !important;
	}
	.border-lg-top-0 {
		border-top: 0 !important;
	}
	.border-lg-right-0 {
		border-right: 0 !important;
	}
	.border-lg-bottom-0 {
		border-bottom: 0 !important;
	}
	.border-lg-left-0 {
		border-left: 0 !important;
	}
}

@media only screen and (min-width: 1200px) {
	.border-xl {
		border: 1px solid #DEE2E6 !important;
	}

	.border-xl-top {
		border-top: 1px solid #DEE2E6 !important;
	}

	.border-xl-right {
		border-right: 1px solid #DEE2E6 !important;
	}

	.border-xl-bottom {
		border-bottom: 1px solid #DEE2E6 !important;
	}

	.border-xl-left {
		border-left: 1px solid #DEE2E6 !important;
	}

	.border-xl-0 {
		border: 0 !important;
	}

	.border-xl-top-0 {
		border-top: 0 !important;
	}

	.border-xl-right-0 {
		border-right: 0 !important;
	}

	.border-xl-bottom-0 {
		border-bottom: 0 !important;
	}

	.border-xl-left-0 {
		border-left: 0 !important;
	}
}

#ajax-likes-modal {
	.modal-body {
		padding: 1.2rem;

		.list-unstyled {
			margin-bottom: 0;

			.media {
				margin-bottom: 0;

				+ .media {
					margin-top: 1rem !important
				}

				.media-body {
					h5 {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

.title-hr {
	text-align: center;
	height: 24px;
	border-bottom: 1px solid black;
	margin-bottom: 25px;

	span {
		background: #FFF;
		padding: 0 7px;
		font-size: 25px;
	}
}

#alpha-nav {
	margin: 30px 0;
	font-size: 0;

	.alpha-nav__letter-wrap {
		display: inline-block;
		margin: 3px;

		.alpha-nav__letter {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 27px;
			height: 27px;
			background: #203E2B;
			color: #FFF;
			text-transform: uppercase;
			font-size: 13px;
			font-weight: bold;
			line-height: 0;
			border-radius: 50%;

			&:hover {
				text-decoration: none;
				background: #8E8051;
			}
		}
	}
}
