/*||--------------------------------------------------------||**
**||				  <- Site Variables ->					||**
**||--------------------------------------------------------||*/

/* ---- Color Scheme Type ---- */
$scheme-type: "light"; //OPTIONS: dark, light (Leave blank for standard)

/* ---- Colors ---- */
$mildorwild-green: #00883F;
$mildorwild-green-l: lighten($mildorwild-green, 10%);
$mildorwild-green-xl: lighten($mildorwild-green, 20%);
$mildorwild-green-xxl: lighten($mildorwild-green, 30%);
$mildorwild-green-d: darken($mildorwild-green, 10%);
$mildorwild-green-xd: darken($mildorwild-green, 20%);
$mildorwild-green-xxd: darken($mildorwild-green, 30%);

$mildorwild-red: #B42025;
$mildorwild-red-l: lighten($mildorwild-red, 10%);
$mildorwild-red-xl: lighten($mildorwild-red, 20%);
$mildorwild-red-xxl: lighten($mildorwild-red, 30%);
$mildorwild-red-d: darken($mildorwild-red, 10%);
$mildorwild-red-xd: darken($mildorwild-red, 20%);
$mildorwild-red-xxd: darken($mildorwild-red, 30%);

$milawyer-green: #1F3E2C;
$milawyer-green-l: lighten($milawyer-green, 10%);
$milawyer-green-xl: lighten($milawyer-green, 20%);
$milawyer-green-xxl: lighten($milawyer-green, 30%);
$milawyer-green-d: darken($milawyer-green, 10%);
$milawyer-green-xd: darken($milawyer-green, 20%);
$milawyer-green-xxd: darken($milawyer-green, 30%);

$milawyer-accent: #8E8051;
$milawyer-accent-l: lighten($milawyer-accent, 10%);
$milawyer-accent-xl: lighten($milawyer-accent, 20%);
$milawyer-accent-xxl: lighten($milawyer-accent, 30%);
$milawyer-accent-d: darken($milawyer-accent, 10%);
$milawyer-accent-xd: darken($milawyer-accent, 20%);
$milawyer-accent-xxd: darken($milawyer-accent, 30%);

$primary: $mildorwild-red;
$primary-l: lighten($primary, 10%);
$primary-xl: lighten($primary, 20%);
$primary-xxl: lighten($primary, 30%);
$primary-d: darken($primary, 10%);
$primary-xd: darken($primary, 20%);
$primary-xxd: darken($primary, 30%);

$accent: $mildorwild-green;
$accent-l: lighten($accent, 10%);
$accent-xl: lighten($accent, 20%);
$accent-xxl: lighten($accent, 30%);
$accent-d: darken($accent, 10%);
$accent-xd: darken($accent, 20%);
$accent-xxd: darken($accent, 30%);

$neutral: #A9A9A9;

@if $scheme-type == "dark" {
	$neutral: #8C8C8C;
}

$neutral-l: lighten($neutral, 10%);
$neutral-xl: lighten($neutral, 20%);
$neutral-xxl: lighten($neutral, 30%);
$neutral-d: darken($neutral, 10%);
$neutral-xd: darken($neutral, 20%);
$neutral-xxd: darken($neutral, 30%);

$text: #444;

$base: #FFF;
$base-l: lighten($base, 10%);
$base-xl: lighten($base, 20%);
$base-xxl: lighten($base, 30%);
$base-d: darken($base, 10%);
$base-xd: darken($base, 20%);
$base-xxd: darken($base, 30%);

$base-accent: #A9A9A9FF;
$base-accent-l: lighten($base-accent, 4%);
$base-accent-xl: lighten($base-accent, 8%);
$base-accent-xxl: lighten($base-accent, 12%);
$base-accent-d: darken($base-accent, 3%);
$base-accent-xd: darken($base-accent, 7%);
$base-accent-xxd: darken($base-accent, 12%);

@if $scheme-type == "dark" {
	$base: #000;
	$base-accent: #171717;
	$base-accent-l: lighten($base-accent, 4%);
	$base-accent-xl: lighten($base-accent, 8%);
	$base-accent-xxl: lighten($base-accent, 12%);
	$base-accent-d: darken($base-accent, 3%);
	$base-accent-xd: darken($base-accent, 7%);
	$base-accent-xxd: darken($base-accent, 12%);
	$text: #FFF;
} @else if $scheme-type == "light" {
	$base-accent: $neutral-xxl;
	$base-accent-l: lighten($base-accent, 4%);
	$base-accent-xl: lighten($base-accent, 8%);
	$base-accent-xxl: lighten($base-accent, 12%);
	$base-accent-d: darken($base-accent, 3%);
	$base-accent-xd: darken($base-accent, 7%);
	$base-accent-xxd: darken($base-accent, 12%);
}

$white: #FFF;
$white-l: lighten($white, 10%);
$white-xl: lighten($white, 20%);
$white-xxl: lighten($white, 30%);
$white-d: darken($white, 10%);
$white-xd: darken($white, 20%);
$white-xxd: darken($white, 30%);

$black: #000;
$black-l: lighten($black, 10%);
$black-xl: lighten($black, 20%);
$black-xxl: lighten($black, 30%);
$black-d: darken($black, 10%);
$black-xd: darken($black, 20%);
$black-xxd: darken($black, 30%);

$alert: #B00;
$success: #109A09;
$warning: #DC3545;

//Set the max-width breakpoint of when the mobile navigation will be diplayed
$mobile-nav-break: 1199px;


/*||--------------------------------------------------------||**
**||				 	 <- Mixins ->						||**
**||--------------------------------------------------------||*/

@mixin linear-gradient($direction, $color-stops...) {
	background: nth(nth($color-stops, 1), 1);
	background: -webkit-linear-gradient($direction, $color-stops);
	background: -moz-linear-gradient($direction, $color-stops);
	background: -ms-linear-gradient($direction, $color-stops);
	background: -o-linear-gradient($direction, $color-stops);
	background: linear-gradient($direction, $color-stops);
}

@mixin transition($string...) {
	-webkit-transition: $string;
	-moz-transition: $string;
	-ms-transition: $string;
	-o-transition: $string;
	transition: $string;
}

@mixin transition-delay($string) {
	-webkit-transition-delay: $string;
	-moz-transition-delay: $string;
	-ms-transition-delay: $string;
	-o-transition-delay: $string;
	transition-delay: $string;
}

@mixin transform-origin($transform-origins...) {
	-webkit-transform-origin: $transform-origins;
	-moz-transform-origin: $transform-origins;
	-ms-transform-origin: $transform-origins;
	-o-transform-origin: $transform-origins;
	transform-origin: $transform-origins;
}

@mixin transform($string) {
	-webkit-transform: $string;
	-moz-transform: $string;
	-ms-transform: $string;
	-o-transform: $string;
	transform: $string;
}

@mixin perspective-origin($perspective-origins...) {
	-webkit-perspective-origin: $perspective-origins;
	-moz-perspective-origin: $perspective-origins;
	-ms-perspective-origin: $perspective-origins;
	-o-perspective-origin: $perspective-origins;
	perspective-origin: $perspective-origins;
}

@mixin perspective($perspectives...) {
	-webkit-perspective: $perspectives;
	-moz-perspective: $perspectives;
	-ms-perspective: $perspectives;
	-o-perspective: $perspectives;
	perspective: $perspectives;
}

@mixin keyframes($animation-name) {
	@-webkit-keyframes #{$animation-name} {
		@content;
	}
	@-moz-keyframes #{$animation-name} {
		@content;
	}
	@-ms-keyframes #{$animation-name} {
		@content;
	}
	@-o-keyframes #{$animation-name} {
		@content;
	}
	@keyframes #{$animation-name} {
		@content;
	}
}

@mixin animation($str) {
	-webkit-animation: #{$str};
	-moz-animation: #{$str};
	-ms-animation: #{$str};
	-o-animation: #{$str};
	animation: #{$str};
}