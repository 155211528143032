.tooltip {
	z-index: 9901;
	--bs-tooltip-opacity: 1;
}

.text-justify {
	text-align: justify !important;
}

.form-control:disabled, .form-control[readonly] {
	background-color: #E9ECEF;
	opacity: 1;
}