@import "../../../../resources/css/variables";

$border-style: 3px solid $white;

@if $scheme-type == "dark" {
	$border-style: 2px solid $neutral-xd;
}

.polaroid-info-buttons {
	border-top: $border-style;
	border-bottom: $border-style;
	background: $primary;
	padding: 60px 30px 30px;

	@if $scheme-type == "dark" {
		background: $base-accent;
		border-color: $base-accent-xxl;
		border-width: 1px;
	}

	.polaroid-button {
		position: relative;
		display: block;
		background: $white;
		border: 14px solid $white;
		border-bottom: none;
		box-shadow: -8px 8px 10px rgba($black, .3);
		margin-bottom: 30px;
		@include transition(all .35s cubic-bezier(0.57, 0.68, 0, 1.47));

		img {
			width: 100%;
			border: 1px solid #BFBFBF;
			border-top-color: #A9A9A9;
			border-right-color: #A9A9A9;
		}

		.polaroid-title {
			background: $white;
			min-height: 60px;
			padding: 20px;
			margin: 0;
			font-family: "rocksalt", Arial, sans-serif;
			font-size: 1.2em;
			text-align: center;
			color: $primary;
		}

		&:hover {
			@include transform(scale(1.1) rotate(-6deg));
			box-shadow: -25px 25px 10px rgba($black, .3);
			z-index: 1;
		}
	}
}