@import "../../../../resources/css/variables";

#header-wrapper {
	background: $white;
	overflow: hidden;

	#header {
		padding-top: 30px;
		padding-bottom: 30px;

		#main-logo {
			max-width: 100%;
		}

		#header-contact {
			text-align: right;

			.header-phone {
				font-size: 22px;
				font-weight: bold;
				margin-bottom: 0;
			}

			.header-email {
				font-size: 22px;
				font-weight: bold;
				margin-bottom: 0;

				a {
					font-size: 16px;
				}
			}

			@if $scheme-type == "dark" {
				color: $white;

				a {
					color: $white;

					&:hover {
						text-decoration: underline;
					}
				}

				.social-links .social-btn {
					border-color: $white;

					&:hover {
						text-decoration: none;
						background: rgba($white, .2);
					}
				}
			} @else if $scheme-type == "light" {
				color: $neutral-xxd;

				a {
					color: $mildorwild-green;

					&:hover {
						text-decoration: underline;
					}
				}

				.social-links .social-btn {
					border-color: $mildorwild-green-l;
					color: $mildorwild-green;

					&:hover {
						text-decoration: none;
						background: rgba($mildorwild-green-d, .08);
					}
				}
			}
		}

		#header-quote {
			display: flex;
			align-items: center;

			i {
				font-size: 40px;
				margin-right: 10px;
				color: $mildorwild-green;
			}

			p {
				font-size: 15px;
				font-weight: bold;
				font-style: italic;
				color: darken($neutral, 5%);
				margin-bottom: 0;

				@if $scheme-type == "dark" {
					color: $neutral-xl;
				}
			}
		}
	}
}